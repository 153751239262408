import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

import { IconComponent } from '../icon/icon.component';
import { trackByFactory } from '../performance/track-by';
import { TooltipDirective } from '../tooltip/tooltip.directive';

export interface Breadcrumb {
  /** The text shown in the breadcrumb. */
  name: string;
  /** The link to open when the breadcrumb is clicked. */
  href?: string;
  /** The value assigned to the `target` attribute of the breadcrumb. */
  target?: string;
  /** * The icon to display next to the breadcrumb */
  icon?: string;
  /** A class attached to the breadcrumb that can be targeted for custom styling. */
  cssClass?: string;
  /**
   * The content to display in a tooltip when hovering on the breadcrumb. Can be text
   * or HTML markup.
   */
  tooltipContent?: string;
  /**
   * Determines if the breadcrumb's appearance should be highlighted. By default, only
   * the last breadcrumb in a collection is highlighted.
   */
  highlighted?: boolean;
  /**
   * Determines if the breadcrumb is shown when the breadcrumbs component is in dropdown
   * mode. Maximum one breadcrumb in a collection can set this to `true`. If no
   * breadcrumb is assigned this property, the last one in the collection will be shown.
   */
  showWhenDropdown?: boolean;
}

export type DisplayMode = 'dropdown' | 'menu';

export interface BreadcrumbClickedEvent {
  $event: MouseEvent;
  name: string;
}

@Component({
  standalone: true,
  selector: 'capra-breadcrumbs',
  imports: [CommonModule, IconComponent, TooltipDirective],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent implements OnChanges {
  @Input() breadcrumbs: Breadcrumb[] = [];
  @Input() displayMode: DisplayMode = 'menu';
  @Output() breadcrumbClicked = new EventEmitter<BreadcrumbClickedEvent>();
  breadcrumbItems: Breadcrumb[] = [];
  readonly trackByFn = trackByFactory<Breadcrumb>(({ name }) => name);

  ngOnChanges(changes: SimpleChanges): void {
    const breadcrumbs: Breadcrumb[] | undefined = changes['breadcrumbs']?.currentValue;
    const displayMode: DisplayMode | undefined = changes['displayMode']?.currentValue;
    if (breadcrumbs || displayMode) {
      const mobileBreadcrumbs = this.breadcrumbs.filter(({ showWhenDropdown }) => showWhenDropdown);
      if (mobileBreadcrumbs.length > 1) {
        throw new Error('Error in `Capra BreadcrumbsComponent: only one breadcrumb can be shown in dropdown mode');
      }

      if (this.displayMode === 'dropdown') {
        this.breadcrumbItems = [mobileBreadcrumbs[0] || this.breadcrumbs[this.breadcrumbs.length - 1]];
      } else if (this.displayMode === 'menu') {
        this.breadcrumbItems = this.breadcrumbs;
      }
    }
  }
}
