<div class="parent">
    <div class="sort-modal-header" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <div class="heading">
            <div class="title-wrap">
                <h1 class="heading-text">Advanced sort settings</h1>
                <div *ngIf="data.chartTitle" class="chart-title">
                    <span class="chart-title-label">Chart title:&nbsp;</span>
                    <span data-test="chart-title-in-sort-settings">{{data.chartTitle}}</span>
                </div>
            </div>
            <capra-icon-button
                (clicked)="onCancel()"
                class="close-button"
                data-test="mode-dialog-header-close"
                aria-label="close modal"
                icon="icon-close"
            ></capra-icon-button>
        </div>
    </div>
    <div class="content-container">
        <div class="sort-settings-info">Add column sorts to your table using the “Add sort” button.  Sorts will be applied to your table in the same order they appear in this list (top to bottom).</div>
        <div class="menu-title">Sort by</div>
        <div cdkDropList class="sort-list-container" (cdkDropListDropped)="drop($event)">
            <div cdkDrag class="sort-item" *ngFor="let sort of sorts; let i = index;">
                <capra-icon cdkDragHandle class="drag-handle" icon="icon-drag-handle"></capra-icon>
                <mode-labeled-menu
                    buttonHeight="compact"
                    [selected]="sort.selected"
                    [menuItems]="unsortedColumns"
                    [highlighted]="sort.highlighted && !isChanged"
                    (menuChange)="onChangeSortField($event, i)"
                    [placeholder]="'Select a field'"
                ></mode-labeled-menu>
                <div class="sort-select">
                    <div
                        class="sort-icon ascending"
                        [ngClass]="{'selected': !sort.descending}"
                        (click)="setDescending(false, i)"
                        capraTooltip
                        tooltipPlacement="bottom"
                        tooltipSize="mini"
                        [tooltipContent]="'Ascending'">
                        <capra-icon icon="icon-control-sort-ascending"></capra-icon>
                    </div>
                    <div
                        class="sort-icon descending"
                        [ngClass]="{'selected': sort.descending}"
                        (click)="setDescending(true, i)"
                        capraTooltip
                        tooltipPlacement="bottom"
                        tooltipSize="mini"
                        [tooltipContent]="'Descending'">
                        <capra-icon icon="icon-control-sort-descending"></capra-icon>
                    </div>
                </div>
                <div class="remove-sort">
                    <capra-icon (click)="removeSort(i)" icon="icon-close"></capra-icon>
                </div>
            </div>
        </div>
        <div class="add-more-sorts">
            <capra-button
                [disabled]="unsortedColumns.length === 0"
                size="small"
                variant="secondary"
                color="neutral-green"
                text="Add sort"
                icon="icon-plus-large"
                (clicked)="addMoreSorts()"
                data-test="add-more-sorts">
            </capra-button>
        </div>
    </div>
    <div mat-dialog-actions>
        <div>
            <capra-button text="Cancel" variant="secondary" color="neutral" (clicked)="onCancel()"></capra-button>
            <capra-button [disabled]="isApplyDisabled" text="Apply" color="default" (clicked)="onApply()" data-test="apply-sort-settings"></capra-button>
        </div>
    </div>
  </div>
