import { FormGroup, FormControl, FormBuilder, NonNullableFormBuilder, Validators } from '@angular/forms';

export interface AcknowledgeFormData {
  acknowledged: boolean;
}

type Form<TData> = Required<{
  [K in keyof TData]: FormControl<TData[K]>;
}>;

export class AcknowledgeForm extends FormGroup<Form<AcknowledgeFormData>> {
  readonly acknowledged = this.controls.acknowledged;

  constructor(readonly fb: NonNullableFormBuilder = new FormBuilder().nonNullable) {
    super(
      fb.group<Form<AcknowledgeFormData>>({
        acknowledged: fb.control(false, { validators: [Validators.requiredTrue] }),
      }).controls
    );
  }
}
