import { Component, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { TooltipDirective } from '@mode/capra';
import { TimeAgoPipe } from '../pipes/time-ago/time-ago.pipe';
import { format } from 'date-fns-tz';

@Component({
  standalone: true,
  selector: 'mode-time-ago',
  imports: [TimeAgoPipe, TooltipDirective],
  templateUrl: './time-ago.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeAgoComponent implements OnChanges {
  @Input() date: string | number | Date = new Date();
  @Input() format: 'capitalized' | 'lowercase' = 'lowercase';
  @Input() tooltipIsDisabled = false;

  dateTooltip = '';

  public ngOnChanges(changes: SimpleChanges) {
    if (changes['date']) {
      this.updateDate(changes['date'].currentValue);
    }
  }

  updateDate(date: string | number | Date) {
    if (!date) return;
    this.date = typeof date === 'string' ? new Date(date) : date;
    this.dateTooltip = format(this.date, 'MMM dd, yyyy h:mmaaa z');
  }
}
