import { Injectable } from '@angular/core';
import { ConfirmDialog } from '@mode/shared/contract-common';
import { ConfirmDialogComponent, ConfirmDialogData } from '../..';
import { DialogService } from '../dialog/dialog.service';

@Injectable()
export class ConfirmDialogService implements ConfirmDialog {
  readonly MODAL_ID = 'mode-confirm-dialog';

  constructor(private dialogService: DialogService) {}

  open(options: ConfirmDialogData) {
    const confirmDialogRef = this.dialogService.open({
      component: ConfirmDialogComponent,
      matDialogConfig: {
        id: this.MODAL_ID,
        data: {
          dialogTitle: options.dialogTitle || 'Are you sure?',
          dialogText: options.dialogText || 'This action cannot be undone.',
          dialogTemplateRef: options.dialogTemplateRef,
          confirmText: options.confirmText || 'Confirm',
          cancelText: options.cancelText || 'Cancel',
          confirmButtonColor: options.confirmButtonColor || 'alert',
          confirmButtonType: options.confirmButtonType || 'secondary',
          icon: options.icon,
          acknowledgeText: options.acknowledgeText,
        },
      },
    });

    const confirmSubscription = confirmDialogRef.componentInstance.confirm.subscribe(() => {
      if (options.confirmAction) {
        options.confirmAction();
      }
      confirmSubscription.unsubscribe();
    });

    const cancelSubscription = confirmDialogRef.componentInstance.cancel.subscribe(() => {
      if (options.cancelAction) {
        options.cancelAction();
      }
      cancelSubscription.unsubscribe();
    });

    const backdropClickSubscription = confirmDialogRef.backdropClick().subscribe(() => {
      if (options.cancelAction) {
        options.cancelAction();
      }
      backdropClickSubscription.unsubscribe();
    });

    return confirmDialogRef.afterClosed();
  }
}
